<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Products</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Products</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-lg-4">
                                        <label for="register-category_id" class="form-label">Select Category</label>
                                        <select formControlName="category_id" class="form-control" placeholder="Select Category" aria-describedby="register-category_id" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.category_id.errors }">
                                            <option value="0" selected>Select Category</option>
                                            <option *ngFor="let category of categoryDropDown" [value]="category.id">{{category.category_name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-lg-6"></div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-product_code" class="form-label">Product Code</label>
                                        <input type="text" formControlName="product_code" class="form-control" placeholder="Product Code" aria-describedby="register-product_code" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.product_code.errors }" />
                                    </div>
                                    <div class="form-group col-lg-8 ">
                                        <label for="register-product_name" class="form-label">Product Name<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="product_name" class="form-control" id="product_name" placeholder="Product Name" aria-describedby="register-product_name" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.product_name.errors }" />
                                        <div *ngIf="submitted && f.product_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.product_name.errors.required">Product Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-sales_price" class="form-label">Sales Price</label>
                                        <input type="number" formControlName="sales_price" class="form-control" (keyup)="checkNumber($event)" placeholder="Sales Price" aria-describedby="register-sales_price" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.sales_price.errors }" />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-mrp" class="form-label">MRP</label>
                                        <input type="number" formControlName="mrp" class="form-control" (keyup)="checkNumber($event)" placeholder="MRP" aria-describedby="register-mrp" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.mrp.errors }" />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-purchase_price" class="form-label">Purchase Price</label>
                                        <input type="number" formControlName="purchase_price" class="form-control" (keyup)="checkNumber($event)" placeholder="Purchase Price" aria-describedby="register-purchase_price" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.purchase_price.errors }"
                                        />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-discount" class="form-label">Discount (%)</label>
                                        <input type="number" formControlName="discount" class="form-control" (keyup)="checkNumber($event)" placeholder="Discount" aria-describedby="register-discount" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.discount.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-size_title" class="form-label">Size Title</label>
                                        <input type="text" formControlName="size_title" class="form-control" placeholder="Size Title" aria-describedby="register-size_title" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.size_title.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-color_title" class="form-label">Color Title</label>
                                        <input type="text" formControlName="color_title" class="form-control" placeholder="Color Title" aria-describedby="register-color_title" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.color_title.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-unit" class="form-label">Unit</label>
                                        <select formControlName="unit" class="form-control" placeholder="Select Unit" aria-describedby="register-unit" tabindex="9" [ngClass]="{ 'is-invalid': submitted && f.unit.errors }">
                                            <option value="" selected>Select Unit</option>
                                            <option *ngFor="let unit of unitDropDown" [value]="unit.unit_name">{{unit.unit_name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-packing_qty" class="form-label">Packing Qty</label>
                                        <input type="number" formControlName="packing_qty" class="form-control" (keyup)="checkNumber($event)" placeholder="Packing Qty" aria-describedby="register-packing_qty" tabindex="10" [ngClass]="{ 'is-invalid': submitted && f.packing_qty.errors }" />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-master_packing_qty" class="form-label">Master Packing Qty</label>
                                        <input type="number" formControlName="master_packing_qty" class="form-control" (keyup)="checkNumber($event)" placeholder="Master Packing Qty" aria-describedby="register-master_packing_qty" tabindex="11" [ngClass]="{ 'is-invalid': submitted && f.master_packing_qty.errors }"
                                        />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-stock_alert_qty" class="form-label">Stock Alert Qty</label>
                                        <input type="number" formControlName="stock_alert_qty" class="form-control" (keyup)="checkNumber($event)" placeholder="Stock Alert Qty" aria-describedby="register-stock_alert_qty" tabindex="12" [ngClass]="{ 'is-invalid': submitted && f.stock_alert_qty.errors }"
                                        />
                                    </div>
                                    <div class="form-group col-lg-3 ">
                                        <label for="register-min_order_qty" class="form-label">Min. Order Qty</label>
                                        <input type="number" formControlName="min_order_qty" class="form-control" (keyup)="checkNumber($event)" placeholder="Min. Order Qty" aria-describedby="register-min_order_qty" tabindex="12" [ngClass]="{ 'is-invalid': submitted && f.min_order_qty.errors }"
                                        />
                                    </div>



                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_oneAvatarImage" alt="Upload" class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_one" class="form-label">Product Photo 1</label>
                                                        <input class="form-control" type="file" id="product_photo_one" (change)="uploadFile($event,1)" name="product_photo_one" formControlName="product_photo_one" tabindex="13" [ngClass]="{ 'is-invalid': submitted && f.product_photo_one.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_twoAvatarImage" alt="Upload" class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_two" class="form-label">Product Photo 2</label>
                                                        <input class="form-control" type="file" id="product_photo_two" (change)="uploadFile($event,2)" name="product_photo_two" formControlName="product_photo_two" tabindex="14" [ngClass]="{ 'is-invalid': submitted && f.product_photo_two.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_threeAvatarImage" alt="Upload" class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_three" class="form-label">Product Photo 3 </label>
                                                        <input class="form-control" type="file" id="product_photo_three" (change)="uploadFile($event,3)" name="product_photo_three" formControlName="product_photo_three" tabindex="15" [ngClass]="{ 'is-invalid': submitted && f.product_photo_three.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <img [src]="product_selected_photo_fourAvatarImage" alt="Upload" class="img-fluid">
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="register-product_photo_four" class="form-label">Product Photo 4</label>
                                                        <input class="form-control" type="file" id="product_photo_four" (change)="uploadFile($event,4)" name="product_photo_four" formControlName="product_photo_four" tabindex="16" [ngClass]="{ 'is-invalid': submitted && f.product_photo_four.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-12">
                                        <span class="text-mute"><b>Note : </b>Please upload square image for better resolution, like 200*200, 400*400, 550*550</span>
                                    </div>
                                    <div class="form-group col-lg-2 ">
                                        <label for="register-is_active" class="form-label">Status<span class="text-danger">*</span></label>
                                        <select formControlName="is_active" class="form-control" aria-describedby="register-is_active" tabindex="17" [ngClass]="{ 'is-invalid': submitted && f.is_active.errors }">
                                            <option value="1" selected >Active</option>
                                            <option value="0">Deactivate</option>
                                        </select>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col col-sm-12 col-xs-12 col-lg-8">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/products" class="btn btn-secondary btn-block" tabindex="19" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 offset-lg-8 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="isAddMode" tabindex="18" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="!isAddMode" tabindex="18" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>