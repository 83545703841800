import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

@Injectable({
  providedIn: 'root'
})
export class ProductInwardService {

  constructor(private _http: HttpClient) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-inward`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getReportData(start, length, product_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-inward-history`, { start, length,product_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(date:string, remarks:string, inward_details:[],  id:string,vendor:string): Observable<any> {
    if(id == '') {
      return this._http.post(`${environment.apiUrl}save-product-inward`, {date, remarks, inward_details,vendor});
    }else{
      return this._http.post(`${environment.apiUrl}update-product-inward`, {id, date, remarks, inward_details,vendor} )
    }
  }

  edit(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}edit-product-inward`, {id});
  }

  delete(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}delete-product-inward`,{id});
  }

  productSearch(searchText:string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-search-products`,{searchText});
  }

  downloadExcelStockFile(id:string): Observable<any> {
    return this._http.post(`${environment.apiUrl}export-product-inward`,{id});
  }

  uploadFile(productData: any): Observable<any> {
    return this._http.post(`${environment.apiUrl}import-product-inward`, productData).pipe(map(data => {return data;}));
  }
  
}

